import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { hideScrollbars, mounter } from "../../utils"
import { Flyout } from "oj-mithriljs-packages/dist/Flyout/Flyout"
import { Svg } from "../../svg"
import { mapIcon } from "../../types"


export interface IAccountNavigation {
    currentUser: string,
    loyaltyPoints: string,
    menuItems: IAccountNavigationItem[],
    btnText: string
}
export interface IAccountNavigationItem {
    isActive: boolean
    title: string
    url: string
    icon: string
}

export class AccountNavigation extends MithrilTsxComponent<IAccountNavigation> {
    open: boolean = false;
    dom: Element

    view(v: m.Vnode<IAccountNavigation>) {
        return [
            <button class="sub-item" aria-label={v.attrs.btnText} onclick={() => this.open = true}><i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/profile.svg" /></i><span>{v.attrs.btnText}</span></button>,
            <Flyout
                className="AccountNavigation"
                open={this.open}
                onopen={open => {
                    this.open = open

                    if (open)
                        hideScrollbars(true)
                }}
                ondismiss={() => hideScrollbars(false)}
                dismiss
                animate
            >
                <header className="flyout-nav-header">
                    <button className="close" onclick={e => {
                        this.open = false
                        hideScrollbars(false)
                    }}>
                        <Svg src="/FrontendWebpack/dist/assets/newIcons/close.svg" />
                    </button>
                </header>
                <div className="flyout-nav-body">
                    <nav role="navigation">
                        <ul className="flyout-nav-root" style={`transform: translateX(-${0 * 100}%)`}>
                            <li>
                                <span className="h2">{v.attrs.currentUser}</span>
                                {v.attrs.loyaltyPoints && <p>{v.attrs.loyaltyPoints}</p>}
                            </li>
                            {v.attrs.menuItems.map(x =>
                                <li>
                                    <div className="flyout-nav-item">
                                        <a href={x.url} target="_self" aria-label={x.title}>{<Svg src={mapIcon[x.icon]} />}<span>{x.title}</span></a>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </Flyout>]
    }

    oncreate(v: m.VnodeDOM<{}>) {
        this.dom = v.dom
    }
}

mounter<IAccountNavigation>("[data-m-account-navigation]", (r, d) => {
    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <AccountNavigation {...d} />
    })
})